<template>
  <div class="home" style="overflow: hidden">
    <a-layout-content style="width: 100%">
      <!-- 顶部图片 -->
      <a-row align="top" id="top">
        <div style="position: relative">
          <a-carousel autoplay arrows key="a-carousel1" dotsClass="slick-dots">
            <div
              slot="prevArrow"
              class="custom-slick-arrow"
              style="left: 5%; z-index: 9"
            >
              <img
                src="../../public/img/homepage/SVG/轮播左翻滚.svg"
                style="height: 50px; width: 50px"
              />
            </div>
            <div
              slot="nextArrow"
              class="custom-slick-arrow"
              style="right: 5%; z-index: 9"
            >
              <img
                src="../../public/img/homepage/SVG/轮播右翻滚.svg"
                style="height: 50px; width: 50px"
              />
            </div>
            <div v-for="item in carouselpic" :key="item.id">
              <img
                style="width: 100%"
                :src="item.pic"
                v-if="item.video == null"
              />
              <video
                v-if="item.video != null"
                width="1920"
                height="978"
                autoplay
                loop
                :poster="item.pic"
                style="width: 1920px; top: 0; left: 0; object-fit: fill"
              >
                <source :src="item.video" type="video/mp4" />
                您的浏览器不支持Video标签。
              </video>
            </div>
          </a-carousel>
        </div>
        <div
          style="
            width: 100%;
            left: -1%;
            line-height: 100px;
            background-color: rgba(10, 10, 10, 0.8);
            z-index: 99;
            display: flex;
          "
        >
          <div
            style="
              width: 22%;
              position: relative;
              color: white;
              line-height: 1.5rem;
            "
            v-for="item in carouselpicBottom"
            :key="item.id"
          >
            <img :src="item.pic" alt="" style="width: 105%" />
            <div
              style="
                position: absolute;
                top: 15%;
                left: 5%;
                width: 60%;
                font-size: 30px;
              "
            >
              {{ item.title }}
            </div>
            <div
              style="
                position: absolute;
                top: 35%;
                left: 5%;
                width: 40%;
                font-size: 14px;
              "
            >
              {{ item.contents }}
            </div>
          </div>
        </div>
      </a-row>
      <!-- 顶部图片 -->

      <!-- 关于我们 -->
      <a-row
        id="gywm"
        :gutter="16"
        align="top"
        class="row-box-height"
        style="position: relative; height: 100%; margin: 30px 0"
        :style="{ backgroundImage: 'url(' + gsBackgroundImage + ')' }"
      >
        <a-col :span="3"></a-col>
        <a-col :span="18" style="background-color: white; height: 100%">
          <a-row style="margin-bottom: 20px; margin-top: 20px">
            <a-col :span="13">
              <div style="position: relative; height: 500px">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin: 30px;
                    font-size: 40px;
                    font-weight: border;
                    position: absolute;
                    color: black;
                  "
                >
                  广西新乾宇通讯科技有限公司
                </div>
                <div
                  style="
                    margin: 30px;
                    font-size: 16px;
                    position: absolute;
                    top: 15%;
                    line-height: 1.5rem;
                    text-indent: 2em;
                  "
                >
                  <p>
                    广西新乾宇通讯科技有限公司，创立于 1997 年，注册资金 3000
                    万，是由一支高新技术团队创立的创新型企业，总部位于“绿城”南宁。主要从事软件定制开发、硬件生产、大数据平台建设，是信息化系统集成、通讯设备工程、计算机网络技术、安全技术防范、智能化建设、外国政府贷款等多元化业务的服务领跑者，致力于为党政相关部门、企业、公检法司等客户提供全方位的整体解决方案，并已逐步实现科技与政企管理、教育、文化等行业的紧密融合。
                  </p>
                  <p>
                    公司在广西全区10
                    个地市成立了分公司或办事处，形成了丰富、快捷、高效的售前售后服务网络。
                  </p>
                  <p>
                    近年来，公司顺应“文化与科技深度融合”的时代潮流，依托自身丰富的智能化行业经验，积极为各地区文化产业、旅游行业、教育事业，提供信息化系统集成、“互联网
                    +”以及外国政府和国际金融机构贷款的咨询服务，努力为助推广西文化事业、产业高质量、快速发展贡献自己的力量！
                  </p>
                  <p>
                    广西新乾宇通讯科技有限公司愿与社会各界朋友一起共创美好的明天！
                  </p>
                </div>
              </div>
            </a-col>
            <a-col :span="11">
              <img :src="gsSiderImage" style="width: 90%" />
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="3"></a-col>
      </a-row>
      <!-- 关于我们 -->

      <!-- 品牌保证 -->
      <a-row
        :gutter="16"
        align="top"
        class="row-box-height"
        style="
          position: relative;
          height: 550px;
          margin-bottom: 30px;
          background-size: 100% 100%;
        "
        :style="{ backgroundImage: 'url(' + processBackgroundImage + ')' }"
      >
        <a-col :span="3"></a-col>
        <a-col :span="18" style="height: 100%">
          <a-row style="margin-bottom: 20px; margin-top: 20px">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 30px;
                margin-bottom: 0;
                font-size: 35px;
                font-weight: border;
                position: relative;
                color: white;
              "
            >
              选择新乾宇 品牌保证
            </div>
            <div
              style="
                width: 100%;
                line-height: 2rem;
                font-size: 20px;
                white-space: normal;
                overflow-wrap: break-word;
                word-break: break-all;
                line-clamp: 2;
                text-overflow: ellipsis;
                font-size: 16px;
                text-align: center;
                color: white;
              "
            >
              多行业覆盖 满足您的个性化需求
            </div>
          </a-row>
          <a-row
            type="flex"
            justify="space-around"
            align="top"
            v-for="(item1, index1) in listTemp1"
            :key="index1"
          >
            <a-col
              :span="3"
              style="text-align: center"
              v-for="(item2, index2) in item1"
              :key="index2"
            >
              <div style="display: inline-flex">
                <a-card
                  :bordered="false"
                  style="background-color: unset"
                  class="btn-4"
                >
                  <div style="margin: 2rem">
                    <img
                      slot="cover"
                      style="width: 55px; height: 50px"
                      alt="example"
                      :src="item2.pic"
                    />
                  </div>
                  <a-card-meta>
                    <template slot="title">
                      <div style="color: white">{{ item2.title }}</div>
                    </template>
                    <!-- <template slot="description"> {{ item2.content }} </template> -->
                  </a-card-meta>
                </a-card>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="3"></a-col>
      </a-row>
      <!-- 品牌保证 -->

      <!-- 成功案例 -->
      <a-row align="top" id="cgal">
        <a-col :span="18" offset="3" style="text-align: center">
          <div
            style="
              width: 100%;
              top: 35%;
              font-size: 35px;
              font-weight: border;
              white-space: normal;
              overflow-wrap: break-word;
              word-break: break-all;
              line-clamp: 2;
              color: black;
              margin: top 10px;
            "
          >
            软件案例展示 满足不同个性化需求
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="18" offset="3">
          <a-row
            :gutter="16"
            align="top"
            style="margin-top: 30px; height: 60rem"
          >
            <div style="margin: 20px; height: 100%; position: relative">
              <a-row :gutter="[32, 32]" style="height: 100%; width: 100%">
                <a-carousel dotsClass="slick-dots1" data-v-f3f3eg9>
                  <!-- 左右箭头 -->
                  <!-- <div
                slot="prevArrow"
                class="custom-slick-arrow"
                style="left: 20%; z-index: 9; color: black"
              >
                <img src="../../public/img/homepicture/首页/组 1070.png" />
              </div>
              <div
                slot="nextArrow"
                class="custom-slick-arrow"
                style="right: 20%; z-index: 9; color: black"
              >
                <img src="../../public/img/homepicture/首页/组 1071.png" />
              </div> -->
                  <div v-for="(item1, index1) in listTemp" :key="index1">
                    <!-- <a-col
                  :span="8"
                  style="text-align: center; display: flex"
                  v-for="(item2, index2) in item1"
                  :key="index2"
                > -->
                    <div
                      style="
                        width: 30%;
                        height: 27rem;
                        display: inline-flex;
                        align-items: center;
                        place-content: center;
                      "
                      v-for="(item2, index2) in item1"
                      :key="index2"
                      :id="index1 + index2"
                    >
                      <div style="display: block; width: 90%">
                        <a-card
                          :bordered="false"
                          style="
                            width: 100%;
                            height: 100%;
                            text-align: left;
                            box-shadow: 15px 15px 10px -10px rgba(0, 0, 0, 0.2);
                          "
                        >
                          <img
                            slot="cover"
                            alt="example"
                            style="height: 271px"
                            :src="item2.pic"
                          />
                          <a-card-meta>
                            <template slot="title">
                              <div
                                style="
                                  display: -webkit-box;
                                  -webkit-box-orient: vertical;
                                  -webkit-line-clamp: 2;
                                  overflow: hidden;
                                "
                              >
                                {{ item2.title }}
                              </div>
                            </template>
                            <!-- <template slot="description">
                              <div
                                style="
                                  width: 100%;
                                  display: -webkit-box;
                                  -webkit-box-orient: vertical;
                                  -webkit-line-clamp: 2;
                                  overflow: hidden;
                                "
                              >
                                {{ item2.content }}
                              </div>
                            </template> -->
                          </a-card-meta>
                        </a-card>
                      </div>
                    </div>
                    <!-- </a-col> -->
                  </div>
                </a-carousel>
                <!-- <a-col
              :span="8"
              style="text-align: center"
              v-for="(item, index) in picturebag3"
              :key="index"
            >
              <div style="width: 260px; display: inline-flex">
                <a-card hoverable style="width: 260px">
                  <img slot="cover" alt="example" :src="item.pic" />
                  <a-card-meta title="Europe Street beat">
                    <template slot="description"> www.instagram.com </template>
                  </a-card-meta>
                </a-card>
              </div>
            </a-col> -->
              </a-row>
            </div>
          </a-row>
        </a-col>
      </a-row>

      <!-- 成功案例 -->

      <!-- 服务流程 -->
      <a-row align="top" style="height: 600px" id="ywjs">
        <a-row
          style="height: 100%; background-size: 100% 100%"
          :style="{
            backgroundImage: 'url(' + serviceProcessBackgroundImage + ')',
          }"
        >
          <div style="width: 100%; height: 100%; position: relative">
            <div
              style="
                width: 100%;
                height: 100%;
                position: absolute;
                overflow: hidden;
              "
            >
              <!-- <video
                width="1920"
                height="600"
                autoplay
                loop
                style="
                  width: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  object-fit: cover;
                "
              >
                <source
                  src="../../public/img/homepicture/解决方案/3.mp4"
                  type="video/mp4"
                />
                您的浏览器不支持Video标签。
              </video> -->
            </div>
            <!-- 视频遮罩 -->
            <!-- <div
              style="
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.2);
                z-index: 1;
              "
            ></div> -->
            <!-- 服务流程上层 -->
            <div style="position: absolute; top: 10%; width: 100%">
              <a-row style="margin-bottom: 50px; margin-top: 50px; z-index: 3">
                <div
                  style="
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin: 30px;
                    font-size: 35px;
                    font-weight: border;
                    position: relative;
                  "
                >
                  服务流程
                </div>
                <a-col :span="8" :offset="8">
                  <div
                    style="
                      color: white;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      margin: 30px;
                      font-size: 16px;
                    "
                  >
                    我们沉浸于用户体验，帮您挖掘和创新更大商机的可能
                  </div>
                </a-col>
              </a-row>
              <a-row
                type="flex"
                justify="space-around"
                align="top"
                style="text-align: center; z-index: 3"
              >
                <div style="display: inline-flex; width: 60%">
                  <div
                    class="ui-step-wrap"
                    style="position: relative; display: block; width: 100%"
                  >
                    <div
                      class="ui-step-progress"
                      style="
                        width: 100%;
                        background: white;
                        height: 3px;
                        position: absolute;
                        top: 118px;
                        left: 0;
                      "
                    ></div>
                    <div
                      class="ui-step"
                      style="
                        position: relative;
                        z-index: 1;
                        list-style: none;
                        color: white;
                      "
                    >
                      <div
                        class="ui-step-item active"
                        style="
                          width: 11.11%;
                          float: left;
                          display: list-item;
                          text-align: -webkit-match-parent;
                        "
                        v-for="(item, index) in serviceProcess"
                        :key="index"
                      >
                        <div
                          class="ui-step-item-title"
                          style="text-align: center"
                        >
                          <div>
                            <div><img :src="item.pic" /></div>
                            <div>{{ item.title }}</div>
                          </div>
                        </div>
                        <div class="ui-step-item-num" style="margin-top: 18px">
                          <span
                            style="
                              display: inline-block;
                              width: 26px;
                              height: 26px;
                              border-radius: 50%;
                              background: white;
                            "
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a-row>
            </div>
            <!-- 服务流程上层 -->
          </div>
        </a-row>
      </a-row>
      <!-- 服务流程 -->

      <a-row :gutter="16" align="top" style="height: 30px"></a-row>

      <!-- 最新资讯 -->
      <a-row
        id="zxzx"
        :gutter="16"
        align="top"
        class="row-box-height"
        style="position: relative"
      >
        <a-col :span="3"></a-col>
        <a-col :span="18">
          <a-row style="margin-bottom: 20px">
            <div
              style="
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              margin：30px;
              font-size:35px;
              font-weight:border;
              color:black;
            "
            >
              最新资讯
            </div>
            <div
              style="
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              margin：30px;
              font-size:16px;
            "
            >
              利用大数据，指定精准营销策略，连接未来
            </div>
          </a-row>
          <a-row type="flex" justify="space-around" align="middle">
            <a-col :span="10" v-for="item in hangyezixunbag" :key="item.id">
              <div
                style="
                  padding: 30px;
                  height: 208px;
                  width: 560px;
                  margin: 0 auto;
                "
              >
                <a-card
                  :bordered="false"
                  hoverable="true"
                  style="width: 100%"
                  @mouseover="mouseover"
                  @mouseleave="mouseleave"
                >
                  <a-card-meta>
                    <template slot="title">
                      <div
                        style="
                          font-weight: 900;
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 2;
                          overflow: hidden;
                        "
                      >
                        {{ item.title }}
                      </div>
                    </template>
                    <template slot="description">
                      <div
                        style="
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 3;
                          overflow: hidden;
                        "
                      >
                        {{ item.content }}
                      </div>
                    </template>
                  </a-card-meta>
                </a-card>
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4" offset="10" style="text-align: center">
              <router-link
                :to="{ name: '文章列表' }"
                v-scroll-to="'#articleList'"
              >
                <a-button type="" shape="square" size="large"
                  >查看更多</a-button
                >
              </router-link>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="3"></a-col>
      </a-row>
      <!-- 最新资讯 -->

      <a-row :gutter="16" align="top" style="height: 30px"></a-row>
      <a-row
        align="middle"
        type="flex"
        style="height: 200px; background-size: 100% 100%"
        :style="{ backgroundImage: 'url(' + contactBackgroundImage + ')' }"
      >
        <a-col :span="18" offset="3" style="height: 3rem"
          ><div style="color: white; font-size: 35px; display: inline">
            进一步，为您的需求提供解决方案
          </div>
          <a
            href="#lxwm"
            v-scroll-to="'#lxwm'"
            style="display: inline; float: right"
          >
            <a-button type="" shape="round" size="large" style=""
              >联系我们</a-button
            >
          </a></a-col
        >
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
/* 轮播图使用数据 */
const carouselpic = [
  {
    id: 0,
    pic: require("../../public/img/homepage/cutpicture/轮播图1.png"),
  },
  {
    id: 1,
    pic: require("../../public/img/homepage/cutpicture/轮播图2.png"),
    // video: require("../../public/img/homepicture/首页/1.mp4"),
  },
  {
    id: 2,
    pic: require("../../public/img/homepage/cutpicture/轮播图3.png"),
  },
  {
    id: 3,
    pic: require("../../public/img/homepage/cutpicture/轮播图4.png"),
  },
];

/* 轮播图底部图片 */
const carouselpicBottom = [
  {
    id: 0,
    pic: require("../../public/img/homepage/cutpicture/组 1213@2x.png"),
    title: "软件定制开发",
    contents: "移动互联网的迅猛发展，使得互联网软件是实现盈利增收的重要来源。",
  },
  {
    id: 1,
    pic: require("../../public/img/homepage/cutpicture/组 1211@2x.png"),
    title: "云计算与大数据",
    contents:
      "成本低、快速采集、处理和分析技术，从各种超大规模的数据中提取价值。",
  },
  {
    id: 2,
    pic: require("../../public/img/homepage/cutpicture/组 1215@2x.png"),
    title: "运维及驻场服务",
    contents: "规范性日常维护，故障应急响应，设备问题解决等范围。",
  },
  {
    id: 3,
    pic: require("../../public/img/homepage/cutpicture/组 1212@2x.png"),
    title: "智能化建设",
    contents: "智能化建筑的系统组成和基本功能主要由三大部分构成。",
  },
  {
    id: 4,
    pic: require("../../public/img/homepage/cutpicture/组 1214@2x.png"),
    title: "网络安全及等保",
    contents: "信息和信息载体按照重要性等级分级别进行信息安全领域保护的工作。",
  },
];

/* 品牌保证使用数据 */
const ourBusiness = [
  {
    id: 0,
    pic: require("../../public/img/homepage/cutpicture/急速上线@2x.png"),
    title: "急速上线",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 1,
    pic: require("../../public/img/homepage/cutpicture/操作简单.svg"),
    title: "操作简单",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 2,
    pic: require("../../public/img/homepage/cutpicture/拖拽式装修@2x.png"),
    title: "拖拽式装修",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 3,
    pic: require("../../public/img/homepage/cutpicture/跨平台展示@2x.png"),
    title: "跨平台展示",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 4,
    pic: require("../../public/img/homepage/cutpicture/售后支持@2x.png"),
    title: "售后支持",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 5,
    pic: require("../../public/img/homepage/cutpicture/独立后台@2x.png"),
    title: "独立后台",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 6,
    pic: require("../../public/img/homepage/cutpicture/扫码上传@2x.png"),
    title: "扫码上传",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 7,
    pic: require("../../public/img/homepage/cutpicture/模板市场@2x.png"),
    title: "模板市场",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 8,
    pic: require("../../public/img/homepage/cutpicture/多行业模式@2x.png"),
    title: "多行业模式",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 9,
    pic: require("../../public/img/homepage/cutpicture/精美设计@2x.png"),
    title: "精美设计",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 10,
    pic: require("../../public/img/homepage/cutpicture/系统稳定@2x.png"),
    title: "系统稳定",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 11,
    pic: require("../../public/img/homepage/cutpicture/数据统计@2x.png"),
    title: "数据统计",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 12,
    pic: require("../../public/img/homepage/cutpicture/免费升级@2x.png"),
    title: "免费升级",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 13,
    pic: require("../../public/img/homepage/cutpicture/持续更新@2x.png"),
    title: "持续更新",
    content: "这还有两行文字详细描述文案",
  },
];

/* 主要块的banner */
const mainBanner = [
  {
    id: 0,
    pic: require("../../public/img/homepicture/首页/蒙版组 85.png"),
  },
  {
    id: 1,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 48.jpg"),
  },
  {
    id: 2,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 8.png"),
  },
  {
    id: 3,
    pic: require("../../public/img/新乾宇官网/首页/组 1015.png"),
  },
  {
    id: 4,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 48.jpg"),
  },
  {
    id: 5,
    pic: require("../../public/img/新乾宇官网/首页/组 21.png"),
  },
  {
    id: 6,
    pic: require("../../public/img/新乾宇官网/首页/map-pin-fill.png"),
  },
  {
    id: 7,
    pic: require("../../public/img/新乾宇官网/首页/组 21.png"),
  },
];

/* 背景底图 */
const backgroundpic = [
  {
    id: 0,
    pic: require("../../public/img/homepicture/首页/line.svg"),
  },
  {
    id: 1,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 48.jpg"),
  },
];

/* 成功案例 */
const picturebag3 = [
  {
    id: 0,
    pic: require("../../public/img/homepicture/成功案例/案例素材/习酒.png"),
    title: "商城系统",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },

  {
    id: 1,
    pic: require("../../public/img/homepicture/成功案例/案例素材/工会系统.png"),
    title: "工会系统",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 2,
    pic: require("../../public/img/homepicture/成功案例/配图/司法.jpeg"),
    title: "微司法",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 3,
    pic: require("../../public/img/homepicture/成功案例/案例素材/消防.png"),
    title: "消防学法免罚系统",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 4,
    pic: require("../../public/img/homepicture/成功案例/案例素材/涉案停车管理系统.png"),
    title: "涉案停车管理系统",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 5,
    pic: require("../../public/img/homepicture/成功案例/案例素材/生鲜配送.png"),
    title: "生鲜溯源配送系统",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },

  {
    id: 6,
    pic: require("../../public/img/homepicture/成功案例/案例素材/酒店系统.png"),
    title: "酒店系统",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 7,
    pic: require("../../public/img/homepicture/成功案例/案例素材/建材.png"),
    title: "建材市场",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 8,
    pic: require("../../public/img/homepicture/成功案例/案例素材/搬家.png"),
    title: "搬家",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 9,
    pic: require("../../public/img/homepicture/成功案例/案例素材/二手车.png"),
    title: "二手车交易市场",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 10,
    pic: require("../../public/img/homepicture/成功案例/案例素材/点餐系统.png"),
    title: "医院点餐系统",
    content:
      "本系统提供了职工帐号余额结算模式与微信支付结算模式，解决了医院给每位职工发的就餐补贴使用问题；系统内设置有食堂和超市两模块入口，都支持职工帐户及微信支付。后端配有互联网打印机，可以实时打印点餐、商品订单，定时配送等功能。",
  },
  {
    id: 11,
    pic: require("../../public/img/homepicture/成功案例/案例素材/KK饭局.png"),
    title: "KK饭局",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 12,
    pic: require("../../public/img/homepicture/成功案例/案例素材/修车.png"),
    title: "汽车维修服务小程序",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 13,
    pic: require("../../public/img/homepicture/成功案例/案例素材/门锁.png"),
    title: "智能门锁",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 14,
    pic: require("../../public/img/homepicture/成功案例/案例素材/房地产.png"),
    title: "房地产推销",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 15,
    pic: require("../../public/img/homepicture/成功案例/案例素材/党建平台.png"),
    title: "党建平台",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 16,
    pic: require("../../public/img/homepicture/成功案例/案例素材/商贩管理.png"),
    title: "商贩管理",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 17,
    pic: require("../../public/img/homepicture/成功案例/案例素材/母婴.png"),
    title: "母婴店",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 18,
    pic: require("../../public/img/homepicture/成功案例/案例素材/美食城.png"),
    title: "美食城",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 19,
    pic: require("../../public/img/homepicture/成功案例/案例素材/策划.png"),
    title: "策划宣传",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
  {
    id: 20,
    pic: require("../../public/img/homepicture/成功案例/案例素材/便利店商城.png"),
    title: "便利店商城",
    content:
      "新乾宇系统深度接入腾讯云采用腾讯云服务器架构部署全部采用腾讯平台解决方案，数据备份，异地灾备，高可用，分布式部署。内网级直连微信支付。",
  },
];

/* 最新资讯 */
const hangyezixunbag = [
  {
    id: 0,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 3.png"),
    title: "微信小程序，创业者的绝佳选择！！",
    content:
      "淘宝系平台化电商如今越来越难做，而以小程序为入口的移动电商，其去中心化的流量入口让移动电商创业者有了更多的自主性，电商小程序是现在数量最多的小程序之一。",
  },
  {
    id: 1,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 4.png"),
    title: "网站+小程序+公众号结合，助力企业营销新模式",
    content:
      "互联网有很多产品，最早的时候，大家了解最多的就是网站，很多年前网站就是企业在互联网上的门面，而有了自媒体之后，很多企业都把自媒体当做企业面向用户的窗口，现在又有了短视频、视频直播、小程序等更多的产品。",
  },
  {
    id: 3,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 5.png"),
    title: "微商行业如何利用小程序做营销？",
    content:
      "自从有微信以来，多出来了一个行业，那就是微商。虽然在前些年人们微商并不看好，甚至有点“讨厌”图片图片但这并不影响微商的发展，有些“微商人还”赚到了不少的收益图片图片。",
  },
  {
    id: 4,
    pic: require("../../public/img/新乾宇官网/首页/蒙版组 5.png"),
    title: "物业公司开发小程序的效果杠杠的！",
    content:
      "现实生活中，很多行业都有了自己的微信小程序，比较常见的如餐饮行业，因为它有了与互联网相关的利器（小程序）之后，就可以充分利用微信本身庞大的群体资源啦！",
  },
];

/* 滚动SUCCESSFUL CASES文字 */
const scrollSuccessful = [
  {
    title: "SUCCESSFUL CASES",
    date: "2017-12-16",
  },
  {
    title: "SUCCESSFUL CASES",
    date: "2017-12-16",
  },
  {
    title: "SUCCESSFUL CASES",
    date: "2017-12-16",
  },
  {
    title: "SUCCESSFUL CASES",
    date: "2017-12-16",
  },
  {
    title: "SUCCESSFUL CASES",
    date: "2017-12-16",
  },
  {
    title: "SUCCESSFUL CASES",
    date: "2017-12-16",
  },
  {
    title: "SUCCESSFUL CASES",
    date: "2017-12-16",
  },
  {
    title: "SUCCESSFUL CASES",
    date: "2017-12-16",
  },
  {
    title: "SUCCESSFUL CASES",
    date: "2017-12-16",
  },
];

/* 服务流程 */
const serviceProcess = [
  {
    id: 0,
    pic: require("../../public/img/homepage/SVG/需求调研.svg"),
    title: "需求调研",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 1,
    pic: require("../../public/img/homepage/SVG/合同签订.svg"),
    title: "合同签订",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 2,
    pic: require("../../public/img/homepage/SVG/交互设计.svg"),
    title: "交互设计",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 3,
    pic: require("../../public/img/homepage/SVG/视觉设计.svg"),
    title: "视觉设计",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 4,
    pic: require("../../public/img/homepage/SVG/前端开发.svg"),
    title: "前端开发",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 5,
    pic: require("../../public/img/homepage/SVG/后端开发.svg"),
    title: "后端开发",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 6,
    pic: require("../../public/img/homepage/SVG/全面测试.svg"),
    title: "全面测试",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 7,
    pic: require("../../public/img/homepage/SVG/上线部署.svg"),
    title: "上线部署",
    content: "这还有两行文字详细描述文案",
  },
  {
    id: 8,
    pic: require("../../public/img/homepage/SVG/运营推广.svg"),
    title: "运营推广",
    content: "这还有两行文字详细描述文案",
  },
];

export default {
  name: "Home",
  data() {
    return {
      carousel: 1,
      carouselpic,
      carouselpicBottom,
      leftArrow: require("../../public/img/homepage/SVG/轮播左翻滚.svg"),
      rightArrow: require("../../public/img/homepage/SVG/轮播右翻滚.svg"),
      gsSiderImage: require("../../public/img/homepage/cutpicture/公司介绍配图.png"),
      gsBackgroundImage: require("../../public/img/homepage/cutpicture/公司介绍底图.png"),
      processBackgroundImage: require("../../public/img/homepage/cutpicture/蒙版组105@2x.png"),
      serviceProcessBackgroundImage: require("../../public/img/homepage/cutpicture/蒙版组116@2x.png"),
      contactBackgroundImage: require("../../public/img/homepage/cutpicture/联系我们底图.png"),
      mainBanner, //页面主要banner图片存放
      ourBusiness, //
      picturebag3,
      serviceProcess,
      scrollSuccessful, //滚动successful列表
      classOption1: {
        limitMoveNum: 3,
        direction: 1,
        hoverStop: true,
        singleHeight: 60,
        waitTime: 4000,
      }, //轮播图下方新闻滚动
      classOption: { limitMoveNum: 1, direction: 2, hoverStop: false }, //滚动successful配置
      hangyezixunbag,
      backgroundpic,
      activeColor: "transparent", //资讯卡片背景色
    };
  },
  methods: {
    carousel1goto(e) {
      console.log(e.currentTarget.id);
      this.$refs.varousel.goTo(e.currentTarget.id, false);
    },
    callback(key) {
      console.log(key);
    },
    mouseover(e) {
      console.log(e);
      e.currentTarget.style.backgroundColor = "rgba(194, 194, 194,0.3)";
    },
    mouseleave(e) {
      console.log(e);
      e.currentTarget.style.backgroundColor = "transparent";
    },
  },
  computed: {
    // 直接使用listTemp就可以
    listTemp1: function () {
      var list = this.ourBusiness; //当前要拆分的数组
      var arrTemp = [];
      var index = 0;
      var sectionCount = 7; //单页或者单行数量
      for (var i = 0; i < list.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp; //分页数组
    },

    // 分页计算，多组
    listTemp: function () {
      var list = this.picturebag3;
      var arrTemp = [];
      var index = 0;
      var sectionCount = 6;
      for (var i = 0; i < list.length; i++) {
        index = parseInt(i / sectionCount);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(list[i]);
      }
      return arrTemp;
    },
  },
};
</script>

<style scoped>
@import "../../public/css/style.css";

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 100%;
  width: 120%;
  line-height: 0;
  background: #364d79;
  overflow: hidden;
  /* border-radius: 15px; */
}

.ant-carousel >>> .slick-dots {
  display: none !important;
  height: auto;
  left: 13%;
  margin: 0px auto;
  text-align: left;
  bottom: 110px;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: rgb(194, 194, 194);
  opacity: 0.4;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  color: rgb(194, 194, 194);
  opacity: 1;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

.ant-carousel[data-v-f3f3eg9] >>> .slick-slide {
  text-align: center;
  height: 100%;
  width: 100%;
  line-height: 0;
  background: transparent;
  overflow: hidden;
  /* border-radius: 15px; */
}

.ant-carousel[data-v-f3f3eg9] >>> .slick-dots1 {
  display: block !important;
  height: auto;
  margin: 0px auto;
  text-align: center;
  bottom: -50px;
  left: 0;
}

.ant-carousel[data-v-f3f3eg9] >>> .slick-dots li button {
  display: block;
  width: 24px;
  height: 4px;
  padding: 0;
  color: #999b9e !important;
  font-size: 0;
  background: #999b9e !important;
  border: 0;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}

.ant-carousel[data-v-f3f3eg9] >>> .slick-dots li.slick-active button {
  color: #0051da !important;
  width: 48px;
  background: #0051da !important;
  opacity: 1;
}

.ant-carousel[data-v-f3f3eg9] >>> .custom-slick-arrow {
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel[data-v-f3f3eg9] >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel[data-v-f3f3eg9] >>> .custom-slick-arrow:hover {
  opacity: 1;
}

.ant-carousel[data-v-f3f3eg9] >>> .slick-slide h3 {
  color: #fff;
}

.buttom-bar-item {
  height: 100%;
  /* 居中 */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.buttom-bar-item-img {
  width: 80%;
  height: 70%;
}

.buttom-bar-item-words {
  width: 80%;
  height: 30%;
  margin: auto;
  overflow: hidden;
  font-size: 20px;
  text-overflow: ellipsis;
}
.radiusleft {
  border-radius: 15px 0 0 15px;
}

.radiusright {
  border-radius: 0 15px 15px 0;
}

.rightbutton {
  background-image: url("../../public/img/新乾宇官网/首页/组 21.png");
}

.warp {
  width: 100%;
  height: 120px;
  margin: 0 auto;
  overflow: hidden;
}
.warp ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.warp ul.ul-item {
  display: flex;
}
.warp ul.ul-item .li-item {
  font-size: 5rem;
  font-weight: 100;
  z-index: 0;
  height: 120px;
  margin-right: 10px;
  line-height: 120px;
  text-align: center;
}

.warp1 {
  width: 100rem;
  height: 60px;
  margin: 0 auto;
  float: left;
  overflow: hidden;
}
.warp1 ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.warp1 ul.ul-item {
  float: left;
  width: 20rem;
}
.warp1 ul.ul-item .li-item {
  color: white;
  float: left;
  height: 60px;
  line-height: 60px;
  font-size: 1rem;
  font-weight: 100;
  z-index: 0;
  width: 100rem;
  margin-right: 10px;
  text-align: left;
  overflow: hidden;
}
</style>