import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "首页",
    mate: { rootPathName: "首页" },
    component: Home,
  },
  {
    path: "/about",
    name: "关于我们",
    mate: { rootPathName: "关于我们" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/articleList",
    name: "文章列表",
    mate: { rootPathName: "文章列表" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/articleList.vue"),
  },
  {
    path: "/article",
    name: "文章页",
    mate: { rootPathName: "文章页" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/article.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  //切换路由回到顶部，如使用vue-scrollto跳转页面则不需要
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: "smooth" }; //平滑滚动到顶部
    }
  },
});

export default router;
