import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// 完整引入
// import Antd from 'ant-design-vue';
import "ant-design-vue/dist/antd.css";
import {
  Button,
  Layout,
  Row,
  Col,
  Menu,
  Tabs,
  Card,
  Icon,
  Input,
  Carousel,
  Popover,
  List,
} from "ant-design-vue";

import scroll from "vue-seamless-scroll";
Vue.use(scroll);

import animated from "animate.css";
Vue.use(animated);

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -119,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.use(Button);
Vue.use(Layout);
Vue.use(Row);
Vue.use(Col);
Vue.use(Menu);
Vue.use(Tabs);
Vue.use(Card);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Carousel);
Vue.use(Popover);
Vue.use(List);
// 完整引入
// Vue.use(Antd);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
