<template>
  <div>
    <a-layout-footer style="background-color: rgba(0, 0, 0, 0.8)">
      <!-- 聚合 -->
      <a-row style="color: white; height: 140px">
        <a-col :span="18" offset="3">
          <a-row>
            <!-- 左侧 -->
            <a-col :span="8">
              <a-row style="height: 50%; margin-bottom: 15px">
                <a-col :span="18" offset="1" style="text-align: center">
                  <img
                    src="../../public/img/homepage/SVG/logo白字.svg"
                    alt=""
                    style="width: 100%"
                  />
                </a-col>
              </a-row>
              <a-row style="height: 50%">
                <a-col
                  :span="14"
                  offset="1"
                  style="
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    background-color: #0051da;
                  "
                >
                  <img
                    src="../../public/img/homepage/SVG/电话白色图标.svg"
                    alt=""
                    style="
                      width: 25px;
                      height: 25px;
                      margin-right: 8px;
                      margin-top: 3px;
                    "
                  />
                  <div style="display: inline; font-size: 25px">
                    0771-5601120
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <!-- 左侧 -->
            <div
              style="
                float: left;
                border-left-style: solid;
                border-width: 1px;
                border-color: #fff;
                height: 124px;
                margin: 0 30px;
              "
            ></div>
            <!-- 中部 -->
            <a-col :span="9">
              <a-row style="text-align: center; height: 1.5rem">
                <a-col :span="4">
                  <router-link
                    :to="{ name: '首页' }"
                    v-scroll-to="'#top'"
                    style="color: white"
                  >
                    首页
                  </router-link>
                </a-col>
                <a-col :span="4">
                  <router-link
                    :to="{ name: '首页' }"
                    v-scroll-to="'#gywm'"
                    style="color: white"
                  >
                    关于我们
                  </router-link>
                </a-col>
                <a-col :span="4">
                  <router-link
                    :to="{ name: '首页' }"
                    v-scroll-to="'#cgal'"
                    style="color: white"
                  >
                    成功案例
                  </router-link>
                </a-col>
                <a-col :span="4">
                  <router-link
                    :to="{ name: '首页' }"
                    v-scroll-to="'#ywjs'"
                    style="color: white"
                  >
                    业务介绍
                  </router-link>
                </a-col>
                <a-col :span="4">
                  <router-link
                    :to="{ name: '首页' }"
                    v-scroll-to="'#zxzx'"
                    style="color: white"
                  >
                    最新资讯
                  </router-link>
                </a-col>
                <a-col :span="4">
                  <router-link
                    :to="{ name: '首页' }"
                    v-scroll-to="'#lxwm'"
                    style="color: white"
                  >
                    联系我们
                  </router-link>
                </a-col>
              </a-row>
              <a-row
                align="bottom"
                type="flex"
                style="text-align: center; margin: 20px 0"
              >
                <a-col :span="4" offset="8" style="font-size: 20px"
                  >联系我们</a-col
                >
                <a-col :span="4">CONTACT US</a-col>
              </a-row>
              <a-row style="text-align: center; margin: 20px 0; height: 1.5rem">
                <a-col :span="18" offset="3">
                  <a
                    target="_blank"
                    style="color: white"
                    href="https://map.baidu.com/search/%E5%B9%BF%E8%A5%BF%E5%8D%97%E5%AE%81%E5%B8%82%E5%85%B4%E5%AE%81%E5%8C%BA%E6%B0%91%E4%B8%BB%E8%B7%AF8%E5%8F%B7%E9%83%BD%E5%B8%82%E7%89%A9%E8%AF%AD7%E5%B1%82701%E5%8F%B7/@12060610.815,2594863.75,19z?querytype=s&da_src=shareurl&wd=%E5%B9%BF%E8%A5%BF%E5%8D%97%E5%AE%81%E5%B8%82%E5%85%B4%E5%AE%81%E5%8C%BA%E6%B0%91%E4%B8%BB%E8%B7%AF8%E5%8F%B7%E9%83%BD%E5%B8%82%E7%89%A9%E8%AF%AD7%E5%B1%82701%E5%8F%B7&c=261&src=0&pn=0&sug=0&l=19&b=(12060130.815,2594636.5;12061090.815,2595091)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&seckey=48f747b6a4e9acf8f2520f8ee4d6cf906709597974d758a959c92c58dcd09954c307c714fd7220fd7e33aca1c5da210430e030eebe9da5a99dda8692678d74d8e48c5264e3c3ea78797fb6df646f99855ca511d6eabfca8a18f09851de68dbee1da64b3a3efb2dd01f0cc4136f7cf76ce0c83c3b8307bdb574200ce7c724b2227f96fd5aa2946a6d163354bc37a2435cdf717eec679bf6ce9a7ddfc8bfcdf0800e12b22c41d2baf6ce2d0fb24bdcf32c853a1649c44c7c193e865bd41bcd1b1cfed7baec7a618a9191a1f71e6b9d7375fef90092795dee6230ef906af62a5fdff0d8cd29473cdfba34968748c3c5c7df&device_ratio=1"
                  >
                    <a-icon
                      type="environment"
                    />广西南宁市兴宁区民主路8号都市物语7层701号
                  </a>
                </a-col>
              </a-row>
            </a-col>
            <!-- 中部 -->
            <div
              style="
                float: left;
                border-left-style: solid;
                border-width: 1px;
                border-color: #fff;
                height: 124px;
                margin: 0 30px;
              "
            ></div>
            <!-- 右侧 -->
            <a-col :span="3" style="text-align: center">
              <div style="width: 100%">
                <div style="margin-bottom: 20px">新乾宇微信公众号</div>
                <img
                  style="object-fit: contain; height: 80px; width: 80px"
                  :src="xqyQrCode"
                />
              </div>
            </a-col>
            <!-- 右侧 -->
          </a-row>
        </a-col>
      </a-row>
      <!-- 聚合 -->
      <!-- 各分公司联系方式 -->
      <a-row id="lxwm">
        <a-col :span="18" offset="3">
          <a-row>
            <a-col :span="24"
              ><div
                style="
                  border-left: 5px solid #0051da;
                  color: white;
                  margin: 20px;
                  padding-left: 10px;
                "
              >
                详细联系方式
              </div></a-col
            >
          </a-row>
          <a-row>
            <a-col :span="8" v-for="(item, index) in branch" :key="index">
              <div style="width: 100%; height: 170px">
                <a-card
                  :bordered="false"
                  style="width: 100%; background-color: unset"
                >
                  <a-card-meta>
                    <div slot="title" style="color: white">
                      {{ item.branch }}
                    </div>
                    <div slot="description" style="color: white; opacity: 0.6">
                      <p>{{ item.contact }}：{{ item.phone }}</p>
                      <p v-if="item.address">详细地址：{{ item.address }}</p>
                    </div>
                  </a-card-meta>
                </a-card>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <!-- 各分公司联系方式 -->
      <a-row>
        <a-col :span="18" :offset="3">
          <a-row
            style="margin: 20px; border-top: 1px solid rgba(255, 255, 255, 0.1)"
          >
            <div style="margin-top: 20px">
              <p align="center">
                <a
                  style="color: white"
                  href="https://beian.miit.gov.cn"
                  target="_blank"
                  >桂ICP备18008156号-2</a
                >
              </p>
            </div>
          </a-row>
        </a-col>
      </a-row>
    </a-layout-footer>
  </div>
</template>

<script>
/* 页底联系方式 */
const branch = [
  {
    id: 0,
    city: "南宁",
    branch: "南宁总公司",
    address: "广西南宁市兴宁区民主路 8 号都市物语 7 层 701 号",
    phone: "15077166909",
    contact: "莫经理",
  },
  {
    id: 1,
    city: "百色",
    branch: "百色分公司",
    address: "百色市那毕大道百色投资大厦左塔 2328 室",
    phone: "13557069511",
    contact: "黄经理",
  },
  {
    id: 2,
    city: "柳州",
    branch: "柳州分公司",
    address: "柳州市海关南路 6 号东堤新都 B 区 -35 号",
    phone: "13877222101",
    contact: "廖经理",
  },
  {
    id: 3,
    city: "桂林",
    branch: "桂林分公司",
    address: "桂林市临桂区鲁山路西城雅居 10 栋 1 单元 401-501 室",
    phone: "18177331134",
    contact: "周经理",
  },
  {
    id: 4,
    city: "河池",
    branch: "河池分公司",
    address: "河池市金城江区金福路 45 号 2 栋 601 号",
    phone: "15078808727",
    contact: "谭经理",
  },
  {
    id: 5,
    city: "北海",
    branch: "北海分公司",
    address: "北海市北京路华武花园 A1 三楼",
    phone: "13607798160",
    contact: "林经理",
  },
  {
    id: 6,
    city: "玉林",
    branch: "玉林分公司",
    address: "玉林市清宁路小区西区 42 栋 1 号楼",
    phone: "15078802162",
    contact: "梁经理",
  },
  {
    id: 7,
    city: "梧州",
    branch: "梧州分公司",
    address: "梧州市长洲区金湖南路 118 号聚宝新城 70 号",
    phone: "15078803060",
    contact: "莫经理",
  },
  {
    id: 8,
    city: "来宾",
    branch: "来宾分公司",
    address: "来宾市迎宾路 229 号巴黎国际商贸城 8 号商住楼 17 层 701、706 号",
    phone: "18107721149",
    contact: "陆经理",
  },
  {
    id: 9,
    city: "贵港",
    branch: "贵港分公司",
    address: "贵港市港北区龙岗小区56号",
    phone: "15078809006",
    contact: "姜经理",
  },
  {
    id: 10,
    city: "贺州",
    branch: "贺州分公司",
    address: "贺州市八步区灵凤路 53 号碧桂园 6 栋 1 单元 02 号 1502",
    phone: "18207714005",
    contact: "李经理",
  },
  {
    id: 11,
    city: "防城港",
    branch: "防城港分公司",
    address: "",
    phone: "13607798160",
    contact: "林经理",
  },
  {
    id: 12,
    city: "钦州",
    branch: "钦州分公司",
    address: "",
    phone: "13607798160",
    contact: "林经理",
  },
  {
    id: 13,
    city: "崇左",
    branch: "崇左分公司",
    address: "",
    phone: "15077166909",
    contact: "莫经理",
  },
];

export default {
  name: "footer",
  data() {
    return {
      branch,
      xqyQrCode: require("../../public/img/新乾宇官网/xqyQrCode.jpg"),
    };
  },
  methods: {
    callback(key) {
      console.log(key);
    },
  },
};
</script>

<style scoped>
a:active,
a:hover {
  zoom: 1.1;
}
</style>