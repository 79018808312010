<template>
  <a-locale-provider :locale="locale">
    <div id="app" class="custom-body">
      <a-layout id="components-layout-demo-top" class="layout">
        <a-layout-header
          style="
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 999;
            box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.1);
          "
        >
          <a-row
            type="flex"
            justify="space-around"
            align="middle"
            style="height: 100%"
          >
            <a-col :span="1"></a-col>
            <a-col :span="7">
              <div class="logo">
                <img src="../public/img/homepicture/logo.svg" class="logoimg" />
              </div>
            </a-col>
            <a-col :span="11">
              <a-row :gutter="10" align="middle" style="font-size: 16px">
                <router-link :to="{ name: '首页' }" v-scroll-to="'#top'">
                  <a-col :span="4" style="color: black">首页</a-col>
                </router-link>
                <router-link :to="{ name: '首页' }" v-scroll-to="'#gywm'">
                  <a-col :span="4" style="color: black">关于我们</a-col>
                </router-link>
                <router-link :to="{ name: '首页' }" v-scroll-to="'#cgal'">
                  <a-col :span="4" style="color: black">成功案例</a-col>
                </router-link>
                <router-link :to="{ name: '首页' }" v-scroll-to="'#ywjs'">
                  <a-col :span="4" style="color: black">业务介绍</a-col>
                </router-link>
                <router-link :to="{ name: '首页' }" v-scroll-to="'#zxzx'">
                  <a-col :span="4" style="color: black">最新资讯</a-col>
                </router-link>
                <router-link :to="{ name: '首页' }" v-scroll-to="'#lxwm'">
                  <a-col :span="4" style="color: black">联系我们</a-col>
                </router-link>
              </a-row>

              <!-- <a-menu
                theme="light"
                mode="horizontal"
                :default-selected-keys="['5']"
                :selectedKeys="[selectedKeys]"
                :style="{ lineHeight: '119px' }"
                @click="handleClick"
              >
                <a-menu-item key="0"
                  ><img src="../public/img/新乾宇官网/搜索.png" alt=""
                /></a-menu-item>
                <a-menu-item key="1"
                  ><a href="/#info"
                    ><img src="../public/img/新乾宇官网/资讯.png" alt="" /></a
                ></a-menu-item>
                <a-menu-item disabled style="cursor: unset; font-size: 30px"
                  >|</a-menu-item
                >
                <a-menu-item key="关于我们"
                  ><router-link :to="{ name: '关于我们' }"
                    >关于我们</router-link
                  ></a-menu-item
                >
                <a-menu-item key="解决方案">
                  <router-link :to="{ name: '解决方案' }">解决方案</router-link>
                </a-menu-item>
                <a-menu-item key="成功案例">
                  <router-link :to="{ name: '成功案例' }">成功案例</router-link>
                </a-menu-item>
                <a-menu-item key="首页">
                  <router-link :to="{ name: '首页' }">首页</router-link>
                </a-menu-item>
              </a-menu> -->
            </a-col>
            <a-col :span="3">
              <div style="height: 100%; width: 15rem; line-height: 2rem">
                <div
                  style="
                    height: 30%;
                    font-size: 30px;
                    font-weight: 500;
                    color: black;
                  "
                >
                  18607818323
                </div>
                <div
                  style="
                    height: 30%;
                    font-size: 30px;
                    font-weight: 500;
                    color: black;
                  "
                >
                  0771-5601120
                </div>
                <a-popover placement="bottom">
                  <template slot="content">
                    <img
                      style="object-fit: contain; height: 130px; width: 130px"
                      :src="xqyQrCode"
                    />
                  </template>
                  <div
                    style="
                      height: 30%;
                      font-size: 16px;
                      font-weight: 900;
                      width: 60%;
                    "
                  >
                    <a-icon type="qrcode" />官方微信公众号
                  </div>
                </a-popover>
              </div>
            </a-col>
            <a-col :span="1"></a-col>
          </a-row>
        </a-layout-header>
        <div style="height: 119px"></div>

        <router-view></router-view>
        <footer1></footer1>
      </a-layout>
    </div>
  </a-locale-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import footer1 from "./views/footer.vue";
export default {
  components: { footer1 },
  data() {
    return {
      logoNoneDisplay: false,
      locale: zhCN,
      selectedKeys: this.$route.meta.rootPathName,
      xqyQrCode: require("../public/img/新乾宇官网/xqyQrCode.jpg"),
    };
  },
  methods: {
    handleClick(e) {
      console.log("click ", e);
      console.log(this.$route);
      this.selectedKeys = e.key;
    },
  },
  mounted() {
    console.log(this.$route);
    this.selectedKeys = this.$route.meta.rootPathName;
  },
  watch: {
    $route: function () {
      this.selectedKeys = this.$route.meta.rootPathName;
    },
    window: function () {},
  },
};
</script>
<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }

.custom-body {
  box-sizing: border-box;
  min-width: 1900px;
  height: 100%;
  width: 100%;
}

.logoNoneDisplay {
  display: none;
}

#components-layout-demo-top .logo {
  // width: 500px;
  float: left;
  height: 80px;
  // position: relative;
  // top: 50%; /*偏移*/
  // margin-top: -20px;
}

#components-layout-demo-top .logo .logoimg {
  height: 80px;
}
.ant-layout {
  background: transparent !important;
}

.ant-layout-header {
  height: 119px !important;
  background-color: white !important;
}

.ant-menu-light {
  background: transparent !important;
}

.ant-menu-item {
  float: right;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 16px;
}

.ant-menu-item-active {
  float: right;
  top: 0 !important;
  border-bottom: 0px !important;
  font-size: 16px;
}

.ant-menu-item-selected {
  float: right;
  border-bottom: 0px !important;
  background-image: unset;
  font-size: 16px;
}

.ant-menu-item-selected > a {
  color: black !important;
}

.ant-menu-horizontal {
  border-bottom: 0px !important;
}
</style>

